import { useEffect, useState } from "react";
import Header from "./Header";
import './Onboarding.css'
import { useDispatch, useSelector } from "react-redux";
import { logout, selectAppAuth } from "../../../services/auth/authSlice";
import { checkUserFields, selectUserInfo, updateUserData, updateUserField } from "../../../features/User/userSlice";
import { selectCurrentLang, selectTranslations } from "../../../services/i18n/i18nSlice";

import {TitleH1} from "../../../components/TitleH1";
import {Text} from "../../../components/Text";
import {Button} from "../../../components/Button";
import Form from 'react-bootstrap/Form';
import Select from 'react-select'
import { toast } from "react-toastify";


import img_check from '../../../assets/images/check.svg'
import img_close from '../../../assets/images/close.svg'

import { Worker } from '@react-pdf-viewer/core';
// Import the main component
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';



import img_1 from './images/step-1.svg'
import img_2 from './images/step-2.svg'
import img_3 from './images/step-3.svg'
import img_4 from './images/step-4.svg'
import img_pc from './images/computer-loading.svg'

import { selectAppPersonalizations } from "../../../services/utils/appInfoSlice";
import { API_URL, DEFAULT_FIELDS } from "../../../config/constants";
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "../../../components/_forms/CustomDatePicker";
import { SelectOption } from "../../../components/_forms/CustomSelectOption";
import { CustomSelectValueContainer } from "../../../components/_forms/CustomSelectValueContainer";



export default function OnBoarding(props) {

    const getUserDefaultData = () => {
        return userInfo?.user_fields?.filter((f)=>{
            if(f.field_level === 0){
                if(DEFAULT_FIELDS.includes(f.field_id))
                    if(userInfo?.user_data[f.field_id])
                        return f
                else{
                    if(userInfo?.user_data?.extra_fields.find(e => e.field_id === f.field_id)?.values)
                        return f
                } 
            } 
        }) || []
    }

    const calculateProgress = () => {
        if(currentStep === 0)
            return 5
        if(currentStep === 1)
            return 15
        if(currentStep === 2){
            return 30 + parseInt((getUserDefaultData()?.length*65)/userInfo?.user_fields?.length )
        }
        if(currentStep === 3)
            return 100
    }

    const [currentStep, setCurrentStep] = useState(0)
    const personalizations = useSelector(selectAppPersonalizations)
    const userAuth = useSelector(selectAppAuth)
    const userInfo = useSelector(selectUserInfo)
    const t = useSelector(selectTranslations);
    const missingFields = useSelector(checkUserFields)
    const currentLang = useSelector(selectCurrentLang)
    const dispatch = useDispatch()

    let navigate = useNavigate()

    const [hasSeenPrivacy, setHasSeenPrivacy] = useState(false);
    const [validatedForm, setValidatedForm] = useState(false);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        setValidatedForm(true);
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            let user_data = []
            let field_id = null
            userInfo?.user_fields?.forEach((f)=>{
                field_id = f.field_id
                if(f.field_level === 0 || f.field_level === 1){
                    if(DEFAULT_FIELDS.includes(field_id))
                        user_data.push({field_id: field_id, field_values:userInfo?.user_data[field_id]})
                    else{
                        const field_value = userInfo?.user_data?.extra_fields?.find(e => e.field_id === field_id)?.values
                        if(field_value)
                            user_data.push({field_id: field_id, field_values:f.field_type === "datetime" ? `${new Date(field_value).toISOString().substring(0,19)}+00:00` : field_value})
                    } 
                }
            })
            dispatch(updateUserData({user_data: user_data})).then(data => {
                if (data.payload) {
                    setCurrentStep(currentStep+1)
                    setTimeout(()=>{navigate('/', {replace:true})}, 5000)
                } else {
                    toast.error(t.error.error_saving)
                }
            })    
            event.preventDefault();
            event.stopPropagation();
        }
        
    };

    const handleEndPrivacyDocument = (p) => {
        if(p.currentPage === p.doc?._pdfInfo?.numPages -1)
            setHasSeenPrivacy(true)
    }

    const handleConfirmLegal = () => {
        const user_data = [{ "field_id" : "legal_checks", 
            "field_values": {
                "internal_legal_check" : true,
                "external_legal_check" : true
            }
        }]
        dispatch(updateUserData({user_data: user_data})).then((data)=>{
            if (data.payload) {
                setCurrentStep(currentStep+1)
            } else {
                toast.error(t.error.error_saving)
            }
        })
    }
    
    useEffect(()=>{
        if(userAuth !== null && userInfo && userInfo?.user_data !== null && userInfo?.user_fields !== null){
            if(userAuth.is_new_user ) {
                setCurrentStep(0)
            }else if (!userInfo?.user_data?.legal_checks?.external_legal_check) {
                setCurrentStep(1)
            } else if (missingFields) {
                setCurrentStep(2)
            } else if (!missingFields && userInfo.is_saved) {
                setCurrentStep(3)
                setTimeout(()=>{navigate('/', {replace:true})}, 5000)
            } 
        }
        
    }, [])
    
    return(
        <div id="onboardingScreen">
            <Header progress={calculateProgress()} />
            {
                currentStep === 0 ?
                    <div className="step mt-5">
                        <TitleH1 className="text-start text-lg-center">{t.onboarding.next_steps}</TitleH1>
                        <ul className="next_steps">
                            <li className="first">
                                <img src={img_1} />
                                <Text className="mb-0">{t.onboarding.step_1}</Text>
                            </li>
                            <li>
                                <img src={img_2} />
                                <Text className="mb-0">{t.onboarding.step_2}</Text>
                            </li>
                            <li>
                                <img src={img_3} />
                                <Text className="mb-0">{t.onboarding.step_3}</Text>
                            </li>
                            <li>
                                <img src={img_4} />
                                <Text className="mb-0">{t.onboarding.step_4}</Text>
                            </li>
                        </ul>
                        <div className="bottom_controller">
                            <Button className="w-100 mx-auto" onClick={()=>{setCurrentStep(currentStep+1)}}>
                                {t.buttons.begin}
                            </Button>
                        </div>
                    </div>
                : ""
            }
            {
                currentStep === 1 ?
                    <div className="step mt-3 mt-lg-3 fade-in-animation">
                        <TitleH1 className="text-left text-lg-center">{t.onboarding.terms_and_conditions}</TitleH1>
                        <Text className="text-left text-lg-center">{t.onboarding.terms_and_conditions_text}</Text>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        </Worker>
                        <div style={{height:'65vh',width:"100%"}}>
                            <Viewer fileUrl={`${API_URL}${personalizations?.privacy_document_url}`} 
                                onPageChange={handleEndPrivacyDocument} 
                                plugins={[
                                    // Register plugins
                                    defaultLayoutPluginInstance
                                ]}
                            />
                        </div>
                        <div className="bottom_controller d-flex justify-content-center">
                            <Button className="d-flex justify-content-between align-items-center" color={"secondary"} onClick={()=>{dispatch(logout())}}>
                                <img className="me-1" src={img_close} />
                                {t.buttons.reject}
                            </Button>
                            <Button 
                                className="ms-2 d-flex justify-content-between align-items-center" 
                                disabled={(!hasSeenPrivacy && personalizations?.privacy_document_url)
                                    || (hasSeenPrivacy && !personalizations?.privacy_document_url)
                                } 
                                onClick={handleConfirmLegal}
                            >
                                <img className="me-1" src={img_check} />
                                {t.buttons.accept}
                            </Button>
                        </div>
                    </div>
                : ""
            }
            {
                currentStep === 2 ?
                    <div className="step mt-3 mt-lg-3 fade-in-animation">
                        <TitleH1 className="text-left text-lg-center">{t.onboarding.user_data}</TitleH1>
                        <Text className="text-left text-lg-center">{t.onboarding.user_data_text}</Text>
                        <Form noValidate validated={validatedForm} onSubmit={handleSubmit} className="w-100">
                        {
                            userInfo?.user_fields?.map((f)=>{
                                const field_id = f.field_id
                                if(DEFAULT_FIELDS.includes(field_id) && f.field_level === 0){
                                    return(
                                        <Form.Group controlId={field_id} key={field_id} className="mb-3">
                                            <FloatingLabel
                                                controlId={field_id}
                                                label={f.field_names[currentLang]}
                                                key={field_id}
                                            >
                                                <Form.Control required type={f.field_type} placeholder={f.field_names[currentLang]} value={userInfo?.user_data[field_id] || ''} onChange={(e)=>dispatch(updateUserField({value: e.target.value, field_id: field_id, type:'default'}))}/>
                                                <Form.Control.Feedback type="invalid" >
                                                    <small className="text-danger">{t.forms.invalid_input}</small>
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Form.Group>
                                    )
                                } else if(f.field_level === 0 || f.field_level === 1) {
                                    return(
                                        <Form.Group controlId={field_id} key={field_id} className="mb-3">
                                            {
                                                f.field_type === 'datetime'?
                                                <FloatingLabel
                                                    controlId={field_id}
                                                    label={f.field_names[currentLang]}
                                                    key={field_id}
                                                >
                                                    <Form.Control required={f.field_level === 0} type={'date'} value={userInfo?.user_data?.extra_fields.find(e => e.field_id === field_id)?.values?.substring(0,10) || ''}  onChange={(e)=>dispatch(updateUserField({value: e.target.value, field_id: field_id, type:'extra'}))}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        <small className="text-danger">{t.forms.invalid_input}</small>
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                                : ""
                                            }
                                            {
                                                f.field_type === 'text'?
                                                <FloatingLabel
                                                    controlId={field_id}
                                                    label={f.field_names[currentLang]}
                                                    key={field_id}
                                                >
                                                    <Form.Control required={f.field_level === 0} type={f.field_type} placeholder={f.field_names[currentLang]} value={userInfo?.user_data?.extra_fields.find(e => e.field_id === field_id)?.values || ''} onChange={(e)=>dispatch(updateUserField({value: e.target.value, field_id: field_id, type:'extra'}))}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        <small className="text-danger">{t.forms.invalid_input}</small>
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                                    : ""
                                            }
                                            {
                                                f.field_type === 'singular' ||  f.field_type === "multiple"?
                                                    <>
                                                        <Select
                                                            options={f.choices?.map((c)=>{return({ value: c, label: c })})}
                                                            isMulti={f.field_type === "multiple"}
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            classNamePrefix="react-select"
                                                            required={f.field_level === 0}
                                                            components={{
                                                                Option: SelectOption,
                                                                ValueContainer: CustomSelectValueContainer
                                                            }}
                                                            className="select-form-control"
                                                            allowSelectAll={true}
                                                            styles={{
                                                                placeholder: (provided, state) => ({
                                                                    ...provided,
                                                                    position: "absolute",
                                                                    top: state.hasValue || state.selectProps.inputValue ? -15 : "15%",
                                                                    transition: "top 0.1s, font-size 0.1s",
                                                                    fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                                                                })
                                                            }}
                                                            placeholder={<div>{f.field_names[currentLang]}</div>}
                                                            value={
                                                                f.field_type === 'singular' ?
                                                                    (userInfo?.user_data?.extra_fields.find(e => e.field_id === field_id)?.values[0]) ? { value: userInfo?.user_data?.extra_fields.find(e => e.field_id === field_id)?.values[0], label: userInfo?.user_data?.extra_fields.find(e => e.field_id === field_id)?.values[0] } : ''
                                                                : userInfo?.user_data?.extra_fields.find(e => e.field_id === field_id)?.values?.map((c)=>{return({ value: c, label: c })}) || ''
                                                            }
                                                            onChange={(e)=>dispatch(updateUserField({value: e, field_id: field_id, type:'extra', field_type:'choices'}))}
                                                        />
                                                        {!userInfo?.user_data?.extra_fields.find(e => e.field_id === field_id)?.values?.length > 0 ?
                                                            <small className="text-danger">{t.forms.invalid_select}</small>
                                                        : null}
                                                    </>
                                                : ""
                                            }
                                        </Form.Group>
                                    )
                                } else return null
                            })
                        }

                        <div className="bottom_controller">
                            <Button className="mx-auto w-100" type={'submit'} >
                                {t.buttons.continue}
                            </Button>
                        </div>
                        </Form>
                    </div>
                : ""
            }
            {
                currentStep === 3 ?
                    <div className="d-flex flex-column align-items-center justify-content-between fade-in-animation">
                        <div className="top">
                            <img src={img_pc} className="mx-auto d-block mt-4" />
                            <TitleH1 className="text-center mt-2 loading_text">{t.onboarding.loading}</TitleH1>
                            <Text className="text-center mt-3">{t.onboarding.loading_text}</Text>
                        </div>
                    </div>
                : ""
            }
        </div>
        
    )
}