import { useDispatch, useSelector } from "react-redux";
import { selectTranslations } from "../../services/i18n/i18nSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { setLoading, setNavbarHide, setNavbarHideMobile } from "../../services/global/globalSlice";

import Navigation from "./_components/Navigation";
import Question from "./_components/Question";
import img_close from '../../assets/images/close.svg'
import correct_img from '../../assets/images/questions/correct.svg'

import tf_logo from '../../assets/images/logo_tf.svg'

import { showError } from "../../features/Error/errorSlice";
import PaperReader from "./_components/PaperReader";
import { Spinner } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import NavigationMobile from "./_components/NavigationMobile";
import { selectAppPersonalizations } from "../../services/utils/appInfoSlice";
import { API_URL } from "../../config/constants";
import EndModal from "../../components/_modals/EndModal";
import { fetchRespondToTrainingQuestion, fetchStartTraining } from "./TrainingApi";
import { normalizeQuestions } from "./trainingUtils";
import LoadingScreen from "./_components/LoadingScreen";

import './Training.css'
import ErrorModal from "../../components/_modals/ErrorModal";

export default function TrainingPerformScreen(){

    const [trainingData, setTrainingData] = useState(null)

    const t = useSelector(selectTranslations)
    const appInfo = useSelector(selectAppPersonalizations)

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {folder_id} = useParams()

    const [confirmModal, setConfirmModal] = useState({
        show: false,
        title: "",
        text: "",
        onClose: null,
        onConfirm: null,
        onSecondary: null,
        confirmCta: "",
        secondaryCta: ""
    })
    const [loading, setLoading] = useState(true)

    const [isSendingAnswer, setIsSendingAnswer] = useState(false);
    let trainingData_ref = useRef(trainingData)
    const [openReader, setOpenReader] = useState(false)

    const [navigationData, setNavigationData] = useState({
        currentQuestion: null,
        currentModule: 0,
        correct_count: 0
    });
    const [errorModalDetails, setErrorModalDetails] = useState({
        show: false,
        text: "",
        title: "",
        onConfirm: ()=>backToHome()
    })

    const closeEndModal = () => {
        setConfirmModal({
            ...confirmModal,
            show: false,
        })
    }

    const backToHome = () => {
        navigate(`/folder/${folder_id}`,{replace:true})
    }

    const prepareResults = (correct_count = navigationData.correct_count) => {
        navigate(`/training/results`,{ 
            replace:true, 
            state:{ trainingData: {
                ...trainingData,
                correct_count: correct_count,
                questions_count: navigationData.questions_count
            }}
        })
    }

    const handleAskEndTraining = () => {
        if(trainingData?.questions?.findIndex(q => q.question_id === navigationData?.currentQuestion) < trainingData.questions?.length -1)
            setConfirmModal({
                ...confirmModal,
                show: true,
                title: t.training.close_training_title,
                text: t.training.close_training_text,
                onClose: ()=>closeEndModal(),
                onConfirm: ()=>backToHome(),
                onSecondary: ()=>closeEndModal(),
                confirmCta: t.buttons.exit,
                secondaryCta: t.buttons.discard
            })
    }

    const handleClosePill = () => {
        if(navigationData.nextQuestion !== null) {
            setNavigationData({
                ...navigationData,
                show_pill: false,
                currentQuestion: trainingData.questions[parseInt(trainingData?.questions?.findIndex(q => q.question_id === navigationData?.currentQuestion))+1].question_id,
            })
        } else {
            setNavigationData({
                ...navigationData,
                show_pill: false
            })
            setTimeout(()=>{
                prepareResults(navigationData.correct_count)
            },500)
        }
    }

    //Exam Answer Handler
    const handleAnswerQuestion =  (type, question, answer_number, selected, block) => {
        
        answer_number = answer_number !== null ? parseInt(answer_number) : null;
        block = parseInt(block);
        let qs = structuredClone(trainingData.questions)
        
        for (let i in qs) {
            if (qs[i].question_id == question.question_id) {
                if(type === "base"){
                    qs[i]["selected_answer_number"] = answer_number;
                    setTrainingData({
                        ...trainingData_ref.current,
                        questions: qs
                    });
                    handleFetchAnswerQuestion(type, question, answer_number)
                    break;
                } else if (type === "multi" || type === "survey") {
                    if (qs[i]["selected_answer_numbers"] && qs[i]["selected_answer_numbers"].includes(answer_number)){
                        if(!selected){
                            qs[i]["selected_answer_numbers"] = qs[i]["selected_answer_numbers"].filter(el => el !== answer_number);
                        }
                    } else {
                        if (qs[i]["selected_answer_numbers"] === null)
                            qs[i]["selected_answer_numbers"] = [];
                        qs[i]["selected_answer_numbers"].push(answer_number);
                    }
                    setTrainingData({
                        ...trainingData_ref.current,
                        questions: qs
                    });
                } else if (type === "block") {
                    qs[i]["selected_answer_number"] = answer_number;
                    setTrainingData({
                        ...trainingData_ref.current,
                        questions: qs
                    });
                    handleFetchAnswerQuestion(type, question, answer_number)
                }
            }
        }
        //TODO: Add logging
        //remoteLog(userAuthToken, 'info', `answered virtual question ${question_number} answer ${answer_number}`)
    }

    const handleFetchAnswerQuestion = async (type, question, answer_number) => {
        setIsSendingAnswer(true);
        let qs = trainingData_ref?.current?.questions
        
        for (let i in qs) {
            if (qs[i].question_id == question.question_id){
                let response = null
                if (type === 'base') {
                    response = await fetchRespondToTrainingQuestion({folder_id: folder_id, question_number: qs[i].question_number, user_training_id: trainingData?.training_id}, { question_type: qs[i].question_type, answer_number:parseInt(answer_number)})
                } else if (type === 'multi' || type === 'block') {
                    response = await fetchRespondToTrainingQuestion({folder_id: folder_id, question_number: qs[i].question_number, user_training_id: trainingData?.training_id}, { question_type: qs[i].question_type, answer_numbers:qs[i].selected_answer_numbers})
                } else if (type === 'block') {
                    const _blocks = {
                        [qs[i].block_number]: {answer_number:qs[i].selected_answer_number}
                    } 
                    //remoteLog(userAuthToken, 'info', `answered virtual question ${question_number} virtual blocks ${JSON.stringify(answers)}`)
                    response = await fetchRespondToTrainingQuestion({folder_id: folder_id, question_number: qs[i].question_number, user_training_id: trainingData?.training_id}, { question_type: type, block_number: qs[i].block_number, answer_number:parseInt(answer_number)})
                }      
                    
                    
                if(!response.success){
                    //remoteLog(userAuthToken, 'error', `error while answering virtual question ${question_number} answer ${answer_number}`)
                    setErrorModalDetails({
                        ...errorModalDetails,
                        show: true,
                        title: t.question.errors.error_answer_title,
                        text: t.question.errors.error_answer_text
                    })
                } else {
                    let _questions = structuredClone(trainingData_ref?.current?.questions)
                    _questions[i]["is_correct_answer"] = response.data?.is_correct_answer
                    _questions[i]["correct_answers"] = response.data?.correct_answers
                    _questions[i]["show_results"] = true
                    setTrainingData({
                        ...trainingData_ref.current,
                        questions: _questions
                    });
                    if(qs[i]?.pill){
                        setTimeout(()=>{
                            setNavigationData({
                                ...navigationData,
                                correct_count: response.data?.is_correct_answer ? navigationData.correct_count+1 : navigationData.correct_count,
                                nextQuestion: i < trainingData.questions?.length -1 ? qs[parseInt(i)+1].question_id : null,
                                show_pill: true
                            })
                        },2000)
                    } else {
                        if(i < trainingData.questions?.length -1) {
                            setTimeout(()=>{
                                setNavigationData({
                                    ...navigationData,
                                    correct_count: response.data?.is_correct_answer ? navigationData.correct_count+1 : navigationData.correct_count,
                                    currentQuestion: qs[parseInt(i)+1].question_id,
                                })
                            },2000)
                        } else {
                            setNavigationData({
                                ...navigationData,
                                correct_count: response.data?.is_correct_answer ? navigationData.correct_count+1 : navigationData.correct_count
                            })
                            setTimeout(()=>{
                                prepareResults(response.data?.is_correct_answer ? navigationData.correct_count+1 : navigationData.correct_count)
                            },2000)
                        }
                    }
                }
                setIsSendingAnswer(false)
            }
        }
    }

    const loadTrainingData = async () => {
        const response = await fetchStartTraining({folder_id:folder_id})
        if(response.success){
            let _trainingData = response.data
            const {_questions, _papers} = normalizeQuestions(_trainingData.training?.questions)
            _trainingData.questions = _questions
            _trainingData.papers = _papers
            setTrainingData(_trainingData)
            console.log(_questions);
            setNavigationData({
                ...navigationData,
                questions_count: _trainingData.questions?.length,
                currentQuestion: _trainingData.questions[0].question_id
            })
            setTimeout(()=>{setLoading(false)},5000)
        } else {
            dispatch(showError({
                title: t.training.errors.start,
                text: t.training.errors.start_text,
                redirect: `/folder/${folder_id}`,
                cta_text: t.modals.back
            }))
        }
    }

    const handleChangePapersData = (papers) => {
        setTrainingData(
            {
                ...trainingData_ref.current,
                papers: papers
            }
        )
    }

    useEffect(()=>{
        if(folder_id){
            loadTrainingData()
        } else {
            navigate('/')
        }
        // eslint-disable-next-line
    },[location])

    useEffect(()=>{
        dispatch(setNavbarHide(true))
        dispatch(setNavbarHideMobile(true))
        // eslint-disable-next-line
    },[trainingData?.training_id])

    useEffect(() => {
        trainingData_ref.current = trainingData
    }, [trainingData])

    return (
        <section id="trainingPerformScreen">
            {
                loading ? 
                    <LoadingScreen />
                : <>
                        { !isMobile ?
                        <div className='exam_header'>
                            <div className="col-3">
                                <img src={appInfo.logo_url ? `${API_URL}${appInfo.logo_url}`: tf_logo} className="d-none d-md-block" style={{height:'50px'}} />
                            </div>
                            <div className="col-9 d-flex justify-content-between">
                            <span>{trainingData?.training.training_title}</span>
                            
                            {isSendingAnswer ? <div className='saving_loader'>
                                <div className="saving_tooltip d-flex align-items-center">
                                    <Spinner animation="border" className="me-2"  /> {t.exam.saving}
                                </div>
                                <div className="saving_backdrop"></div>
                            </div> : ""}
                            </div>
                        </div> : null}
                        {
                            isMobile ? 
                            <div className='training_header'>
                                {isSendingAnswer ? <div className='saving_loader'>
                                    <div className="saving_tooltip d-flex align-items-center"  >
                                        <Spinner animation="border" className="me-2" style={{width:"21px", height:"21px"}} /> 
                                    </div>
                                    <div className="saving_backdrop"></div>
                                </div> : <div style={{width:'32px'}}></div>} 
                                <p className="text-black mb-0"><img src={correct_img} alt="" /> <strong>{navigationData.correct_count}</strong><span style={{color:'#454545',fontWeight:'500'}}>/{parseInt(navigationData.questions_count)}</span> </p>
                                <img src={img_close} alt="" className="pointer" onClick={handleAskEndTraining} />
                            </div> : null
                        }
                        
                        {trainingData && trainingData.questions && trainingData.questions[0]? 
                            <>
                                {
                                    openReader ?
                                        <PaperReader 
                                            expiryTime={navigationData?.globalTimeout} 
                                            data={trainingData?.questions.find(q => q.question_id === navigationData?.currentQuestion) || null} 
                                            questionsPapersData={trainingData?.papers} setQuestionsPapersData={handleChangePapersData} 
                                            paperData={trainingData?.papers?.find(p => p.question_number == trainingData?.questions?.find(q => q.question_id === navigationData?.currentQuestion)?.question_number && p.module_number == trainingData?.questions?.find(q => q.question_id === navigationData?.currentQuestion)?.module_number) || null} 
                                            handleCloseReader={()=>setOpenReader(false)} />
                                    : ""
                                }
                                <section id="examContent">
                                    <div className="row ">
                                        {!isMobile ?
                                            <Navigation 
                                                questions={trainingData?.questions} 
                                                navigationData={navigationData} 
                                                handleEndTraining={handleAskEndTraining}
                                            />
                                        : <NavigationMobile 
                                                questions={trainingData?.questions} 
                                                navigationData={navigationData} 
                                                handleEndTraining={handleAskEndTraining}
                                            />}
                                        <Question 
                                            data={trainingData?.questions.find(q => q.question_id === navigationData.currentQuestion)} 
                                            handleAnswerQuestion={handleAnswerQuestion} 
                                            handleOpenReader={()=>setOpenReader(true)} 
                                            navigationData={navigationData}
                                            handleClosePill={handleClosePill}
                                            handleSendAnswer={handleFetchAnswerQuestion}
                                        />
                                    </div>
                                </section>
                            </>
                        : "" }
                        <EndModal props={confirmModal}/>
                        <ErrorModal props={errorModalDetails} />
                </>
            }
            
        </section>
    )
}