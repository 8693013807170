import { Stream } from "@cloudflare/stream-react";
import { useEffect, useRef, useState } from "react";
import { fetchGetFolder, fetchGetVideo, fetchUpdateUserVideoStatistics } from "./FolderApi";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { showError } from "../../features/Error/errorSlice";
import { useLocation, useParams } from "react-router-dom";
import { setLoading } from "../../services/global/globalSlice";
import { selectTranslations } from "../../services/i18n/i18nSlice";
import { TitleH2 } from "../../components/TitleH2";

import correct_img from '../../assets/images/folders/correct.svg'
import incomplete_img from '../../assets/images/ofa/incomplete_folder.svg'

import FolderTopBar from "./_components/FolderTopBar";
import debounce from "lodash.debounce";
import Skeleton from "react-loading-skeleton";
import { Spinner } from "react-bootstrap";


export default function VideoPlayer() {

    const [videoData, setVideoData] = useState(null)
    const [folderData, setFolderData] = useState(null)
    const dispatch = useDispatch()

    const videoRef = useRef()

    const location = useLocation()
    const {video_id, folder_id} = useParams()

    const t = useSelector(selectTranslations)
    
    const loadVideoData = async () => {
        const response = await fetchGetVideo({video_id: video_id})
        if(response.success){
            setVideoData(response.data)
        } else {
            dispatch(showError({
                title: t.folder.error.loading_video,
                text: t.folder.error.loading_video_text
            }))  
        }
    }

    const loadFolderData = async () => {
        const response = await fetchGetFolder({...(folder_id && {folder_id: folder_id})})
        if (response.success){
            setFolderData(response.data)
            dispatch(setLoading(false))
        } else {
            toast.error(t.training.error_loading_folder)
            dispatch(setLoading(false))
        }
    }

    const handleTrackVideoEnd = async (e) => {
        console.log(e);
        const response = await fetchUpdateUserVideoStatistics({
            video_id: video_id,
            completed: videoData?.completed || videoRef.current?.ended
        })
        if(!response.success)
            console.error(response.error);
        const response_video = await fetchGetVideo({video_id: video_id})
        if(response_video.success){
            setVideoData({
                ...videoData,
                completed: response_video.data.completed
            })
        } 
    }

    const _handleTrackVideoProgress = async () => {
        const response = await fetchUpdateUserVideoStatistics({
            video_id: video_id,
            completed: videoData?.completed || videoRef.current?.ended,
            seconds_elapsed: videoRef.current?.currentTime || 0
        })
        if(!response.success)
            console.error(response.error);
    }

    const handleTrackVideoProgress = debounce(_handleTrackVideoProgress, 300)

    useEffect(()=>{
        if(video_id)
            loadVideoData()
    },[video_id])

    useEffect(()=>{
        if(location.state?.folderData)
            setFolderData(location.state?.folderData)
        else    
            loadFolderData()
    },[location])

    const videoFolderData = folderData?.videos?.find(video => video?.file_id === video_id)

    return(
        <section className="content_section">
            {
                folderData ? <FolderTopBar current={true} folderData={folderData} t={t} /> : null
            }
            
            <div className="mt-5">
                {
                    videoData ?
                        <Stream 
                            controls={true} 
                            streamRef={videoRef}
                            poster={videoData?.poster_image_url}
                            src={videoData?.uid} 
                            currentTime={videoData?.seconds_elapsed}
                            onEnded={handleTrackVideoEnd}
                            onTimeUpdate={handleTrackVideoProgress}
                            className="video_player"
                        />
                    : <div className="video_loader">
                        <Spinner />
                    </div>
                }
                {
                    folderData ? 
                        <div className="d-flex align-items-center mt-4">
                            <img src={!videoData?.completed ? incomplete_img : correct_img} className='ms-1' alt="" />
                            <TitleH2 className="mb-0 ms-1">{videoFolderData?.name}</TitleH2>
                        </div>
                    : null
                }
            </div>
        </section>
    )
}