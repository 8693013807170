import { Stream } from "@cloudflare/stream-react";
import { useEffect, useRef, useState } from "react";
import { fetchGetVideo, fetchUpdateUserVideoStatistics } from "../FolderApi";
import { useDispatch, useSelector } from "react-redux";
import { showError } from "../../../features/Error/errorSlice";
import { selectTranslations } from "../../../services/i18n/i18nSlice";

import debounce from "lodash.debounce";
import { Spinner, SplitButton } from "react-bootstrap";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

export default function VideoPlayerInline({videoId=null, onEnd=null, poster}) {

    const [videoData, setVideoData] = useState(null)
    const [videoReady, setVideoReady] = useState(false)

    const dispatch = useDispatch()

    const videoRef = useRef()

    const t = useSelector(selectTranslations)
    
    const loadVideoData = async () => {
        const response = await fetchGetVideo({video_id: videoId})
        if(response.success){
            setVideoData(response.data)
        } else {
            dispatch(showError({
                title: t.folder.error.loading_video,
                text: t.folder.error.loading_video_text
            }))  
        }
    }

    const handleTrackVideoEnd = async (e) => {
        const response = await fetchUpdateUserVideoStatistics({
            video_id: videoId,
            completed: videoData?.completed || videoRef.current?.ended
        })
        if(!response.success)
            console.error(response.error);
        onEnd()
        const response_video = await fetchGetVideo({video_id: videoId})
        if(response_video.success){
            setVideoData({
                ...videoData,
                completed: response_video.data.completed
            })
        } 
    }

    const _handleTrackVideoProgress = async () => {
        const response = await fetchUpdateUserVideoStatistics({
            video_id: videoId,
            completed: videoData?.completed || videoRef.current?.ended,
            seconds_elapsed: videoRef.current?.currentTime || 0
        })
        if(!response.success)
            console.error(response.error);
    }

    const handleTrackVideoProgress = debounce(_handleTrackVideoProgress, 300)

    useEffect(()=>{
        if(videoId){
            setVideoReady(false)
            loadVideoData()
        }
    },[videoId])

    return(
        <section className="video_player_inline">
            
                {
                    videoData ?
                        <>
                            <Stream 
                                controls={true} 
                                streamRef={videoRef}
                                poster={videoData?.poster_image_url}
                                src={videoData?.uid} 
                                onEnded={handleTrackVideoEnd}
                                onTimeUpdate={handleTrackVideoProgress}
                                onCanPlay={()=>setVideoReady(true)}
                            />
                            {
                                !videoReady &&
                                    <Spinner />
                            }
                        </>
                     :   
                     <>
                        <img src={poster} className='mt-1 video_preview' alt=""/>
                     </>
                     
                }
        </section>
    )
}