import { useDispatch, useSelector } from "react-redux";
import { selectTranslations } from "../../services/i18n/i18nSlice";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import { setLoading, setNavbarHide, setNavbarHideMobile } from "../../services/global/globalSlice";

import Navigation from "./_components/Navigation";
import PaginationControl from "./_components/PaginationControl";
import Question from "./_components/Question";
import img_close from '../../assets/images/close.svg'

import tf_logo from '../../assets/images/logo_tf.svg'

import { fetchEndExam, fetchGetExam, fetchRespondToQuestion, fetchStartExam, fetchStartModule } from "./ExamsApi";
import { showError } from "../../features/Error/errorSlice";
import { resetCurrentExam, selectCurrentExamId } from "../../features/Exam/examSlice";
import { extractPapers, normalizeModules } from "./examUtils";
import PaperReader from "./_components/PaperReader";
import { Spinner } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import NavigationMobile from "./_components/NavigationMobile";
import { selectAppPersonalizations } from "../../services/utils/appInfoSlice";
import { API_URL } from "../../config/constants";
import EndModal from "../../components/_modals/EndModal";
import TimerMobile from "./_components/TimerMobile";
import PaginationMobile from "./_components/PaginationMobile";

export default function ExamPerformScreen(){

    const [examData, setExamData] = useState(null)

    const t = useSelector(selectTranslations)
    const exam_id = useSelector(selectCurrentExamId)
    const appInfo = useSelector(selectAppPersonalizations)
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [confirmModal, setConfirmModal] = useState({
        show: false,
        title: "",
        text: "",
        onClose: null,
        onConfirm: null,
        onSecondary: null,
        confirmCta: "",
        secondaryCta: ""
    })

    const [isSendingAnswer, setIsSendingAnswer] = useState(false);
    let examData_ref = useRef(examData)

    const [updateTimeout, setUpdateTimeout] = useState(null);
    let updateTimeout_ref = useRef(updateTimeout)

    const [openReader, setOpenReader] = useState(false)

    const [navigationData, setNavigationData] = useState({
        currentQuestion: null,
        currentModule: 0,
        isLinearModuleNavigation: true,
        globalTimeout: null,
        modulesCount: 0,
        timeoutId: null
    });

    const remainingQuestions = () => {
        let _count = 0
        examData?.questions?.forEach((q)=>{
            if((q.selected_answer_number!==null && q.selected_answer_number !== undefined && !isNaN(q.selected_answer_number)) || (q.selected_answer_numbers !==null && q.selected_answer_numbers !== undefined && q.selected_answer_numbers?.length !== 0))
                _count++
        })
        return examData?.questions?.length - _count
    }

    //Navigation Handler
    const handleChangeQuestion = (question_id) => {
        const _question = examData?.questions?.find(q=>q.question_id===question_id)
        setNavigationData({
            ...navigationData,
            currentQuestion: question_id,
            currentModule: navigationData?.isLinearModuleNavigation ? 0 : _question?.module_number
        });
    }
    const handleChangeModule = (module_id) => {
        setNavigationData({
            ...navigationData,
            currentModule: module_id
        });
    }

    const handleUpdateGlobalTimeout = (timeout, timeout_id) => {
        setNavigationData({
            ...navigationData,
            globalTimeout: timeout,
            timeoutId: timeout_id
        });
    }

    const handleUpdateConsumedTime = (time) => {
        setExamData({
            ...examData_ref.current,
            consumed_time: time
        })
    }
    
    const handleEndExam = async () => {
        clearInterval(navigationData.timeoutId)
        const response = await fetchEndExam({exam_id: exam_id})
        if(response.success){
            dispatch(resetCurrentExam())
            navigate('/exams/exam_results', {replace:true, state:{examData: examData_ref.current, exam_id:exam_id, results: response.data}})
        } else {
            dispatch(showError({
                title: t.exam.errors.start,
                text: t.exam.errors.start_text,
                redirect: "/exams"
            }))
        }
    }

    const handleEndModule = async () => {
        clearInterval(navigationData.timeoutId)
        const response = await fetchStartModule({exam_id: exam_id, module_number: examData.user_modules[0].module_number + 1})
        if(response.success){
            navigate('/exams/start_module',{replace:true, state:response.data})
        } else {
            dispatch(showError({
                title: t.exam.errors.start,
                text: t.exam.errors.start_text,
                redirect: "/exams"
            }))
        }
    }

    const closeEndModal = () => {
        setConfirmModal({
            ...confirmModal,
            show: false,
        })
    }

    const backToHome = () => {
        clearInterval(navigationData.timeoutId)
        dispatch(resetCurrentExam())
        navigate('/exams',{replace:true})
    }

    const handleAskEndModule = (time) => {
        setConfirmModal({
            ...confirmModal,
            show: true,
            title: t.exam.close_modal_title,
            text: `${t.exam.close_exam_text} ${remainingQuestions()}`,
            onClose: ()=>closeEndModal(),
            onConfirm: ()=>backToHome(),
            onSecondary: ()=>handleEndModule(),
            confirmCta: t.exam.close_pause,
            secondaryCta: t.exam.end_module
        })
    }
    const handleAskEndExam = (time) => {
        setConfirmModal({
            ...confirmModal,
            show: true,
            title: t.exam.close_exam_title,
            text: `${t.exam.close_exam_text} ${remainingQuestions()}`,
            onClose: ()=>closeEndModal(),
            onConfirm: ()=>backToHome(),
            onSecondary: ()=>handleEndExam(),
            confirmCta: t.exam.close_pause,
            secondaryCta: t.exam.close_confirm
        })
    }

    //Exam Answer Handler
    const handleAnswerQuestion = async (type, question, answer_number, selected, block) => {
        setIsSendingAnswer(true);
        answer_number = answer_number !== null ? parseInt(answer_number) : null;
        block = parseInt(block);
        let qs = structuredClone(examData.questions)
        if(type === "base"){
            for (let i in qs) {
                if (qs[i].question_id == question.question_id) {
                    qs[i]["selected_answer_number"] = answer_number;
                    //remoteLog(userAuthToken, 'info', `answered virtual question ${question_number} answer ${answer_number}`)
                    let response = await fetchRespondToQuestion({exam_id: exam_id, question_number: question.question_number, module_number: question.module_number}, { question_type: type, answer_number:parseInt(answer_number)})
                    if(!response.success){
                        //remoteLog(userAuthToken, 'error', `error while answering virtual question ${question_number} answer ${answer_number}`)
                        dispatch(showError({
                            title: t.exam.errors.answer,
                            text: t.exam.errors.answer_text
                        }))
                    } else {
                        setExamData({
                            ...examData_ref.current,
                            questions: qs
                        });
                    }
                    break;
                }
            }
        } else if (type === "multi" || type === "survey") {
            for (let i in qs) {
                if (qs[i].question_id === question.question_id) {
                    if (qs[i]["selected_answer_numbers"] && qs[i]["selected_answer_numbers"].includes(answer_number)){
                        if(!selected){
                            qs[i]["selected_answer_numbers"] = qs[i]["selected_answer_numbers"].filter(el => el !== answer_number);
                        }
                    } else {
                        if (qs[i]["selected_answer_numbers"] === null)
                            qs[i]["selected_answer_numbers"] = [];
                        qs[i]["selected_answer_numbers"].push(answer_number);
                    }
                    //remoteLog(userAuthToken, 'info', `answered virtual question ${question_number} answers ${qs[i]["selected_answer_numbers"].join() || ''}`)
                    let response = await fetchRespondToQuestion({exam_id: exam_id, question_number: question.question_number, module_number: question.module_number}, { question_type: type, answer_numbers: qs[i]["selected_answer_numbers"] })
                    if(!response.success){
                        //remoteLog(userAuthToken, 'error', `error while answering virtual question ${question_number} answers ${qs[i]["selected_answer_numbers"].join() || ''}`)
                        dispatch(showError({
                            title: t.exam.errors.answer,
                            text: t.exam.errors.answer_text
                        }))
                    } else {
                        setExamData({
                            ...examData_ref.current,
                            questions: qs
                        });
                    }
                    break;
                }
            }
        } else if (type === "block") {
            for (let i in qs) {
                if (qs[i].question_id == question.question_id) {
                    qs[i]["selected_answer_number"] = answer_number;
                    console.log(qs[i]);
                    const _blocks = {
                        [question.block_number]: {answer_number:answer_number}
                    } 
                    //remoteLog(userAuthToken, 'info', `answered virtual question ${question_number} virtual blocks ${JSON.stringify(answers)}`)
                    let response = await fetchRespondToQuestion({exam_id: exam_id, question_number: question.question_number, module_number: question.module_number}, { question_type: "block", blocks:_blocks })
                    if(!response.success){
                        dispatch(showError({
                            title: t.exam.errors.answer,
                            text: t.exam.errors.answer_text
                        }))
                    } else {
                        setExamData({
                            ...examData_ref.current,
                            questions: qs
                        });
                    }
                    break;
                }
            }
        } 
        setIsSendingAnswer(false);
    }

    const loadExamData = async () => {
        const response = await fetchGetExam({exam_id:exam_id})
        if(response.success){
            let _examData = response.data
            const {_questions, _papers} = normalizeModules(_examData.user_modules)
            _examData.questions = _questions
            _examData.papers = _papers
            setExamData(_examData)
            console.log(_questions);
            setNavigationData({
                ...navigationData,
                currentQuestion: _examData.questions[0].question_id,
                currentModule: 0,
                isLinearModuleNavigation: _examData?.exam?.time ? false : true,
                modulesCount: _examData?.exam?.modules_count,
            })
            dispatch(setLoading(false))
        } else {
            dispatch(showError({
                title: t.exam.errors.start,
                text: t.exam.errors.start_text,
                redirect: "/exams"
            }))
        }
    }

    const handleChangePapersData = (papers) => {
        setExamData(
            {
                ...examData_ref.current,
                papers: papers
            }
        )
    }

    useEffect(()=>{
        dispatch(setLoading(true))
        if(location.state && exam_id){
            loadExamData()
        } else {
            navigate('/exams')
        }
        // eslint-disable-next-line
    },[location])

    useEffect(() => {
        dispatch(setNavbarHideMobile(true))
        dispatch(setNavbarHide(true))
    }, [examData?.exam_id, exam_id])

    useEffect(() => {
        examData_ref.current = examData
    }, [examData])

    return (
        <section id="examPerformScreen">
            { !isMobile ?
            <div className='exam_header'>
                <img src={appInfo.logo_url ? `${API_URL}${appInfo.logo_url}` : tf_logo} className="d-none d-md-block" style={{height:'50px'}} />
                {isSendingAnswer ? <div className='saving_loader'>
                    <div className="saving_tooltip d-flex align-items-center">
                        <Spinner animation="border" className="me-2" style={{maxwidth:"14px"}} /> {t.exam.saving}
                    </div>
                    <div className="saving_backdrop"></div>
                </div> : ""}
            </div> : null}
            {
                isMobile ? 
                <div className='exam_header'>
                    {isSendingAnswer ? <div className='saving_loader'>
                        <div className="saving_tooltip d-flex align-items-center"  >
                            <Spinner animation="border" className="me-2" style={{width:"21px", height:"21px"}} /> 
                        </div>
                        <div className="saving_backdrop"></div>
                    </div> : <div style={{width:'32px'}}></div>}
                    <TimerMobile 
                        timeout={examData?.exam?.time ? examData?.exam?.time : examData?.user_modules[0]?.time} 
                        consumed_time={examData?.user_modules[0]?.consumed_time} 
                        saveGlobalTimeout={handleUpdateGlobalTimeout}
                        handleEndExam={handleEndExam}
                        handleEndModule={handleEndModule}
                        navigationData={navigationData}
                        userModules={examData?.user_modules} 
                    />
                    <img src={img_close} alt="" className="pointer" onClick={handleAskEndExam} />
                </div> : null
            }
            
            {examData && examData.user_modules && examData.user_modules[0]? 
                <>
                    {
                        openReader ?
                            <PaperReader 
                                expiryTime={navigationData?.globalTimeout} 
                                data={examData?.questions.find(q => q.question_id === navigationData?.currentQuestion) || null} 
                                questionsPapersData={examData?.papers} setQuestionsPapersData={handleChangePapersData} 
                                paperData={examData?.papers?.find(p => p.question_number == examData?.questions?.find(q => q.question_id === navigationData?.currentQuestion)?.question_number && p.module_number == examData?.questions?.find(q => q.question_id === navigationData?.currentQuestion)?.module_number) || null} 
                                handleCloseReader={()=>setOpenReader(false)} />
                        : ""
                    }
                    <section id="examContent">
                        <div className="row ">
                            {!isMobile ?
                                <Navigation 
                                    questions={examData?.questions} 
                                    userModules={examData?.user_modules} 
                                    timeout={examData?.exam?.time ? examData?.exam?.time : examData?.user_modules[0]?.time} 
                                    navigationData={navigationData} 
                                    handleChangeQuestion={handleChangeQuestion} 
                                    handleCloseExam={handleAskEndExam} 
                                    handleCloseModule={handleAskEndModule} 
                                    handleChangeModule={handleChangeModule}
                                    handleEndExam={handleEndExam}
                                    handleEndModule={handleEndModule}
                                    consumed_time={examData.user_modules[0].consumed_time} 
                                    saveGlobalTimeout={handleUpdateGlobalTimeout} 
                                    remainingQuestions={remainingQuestions()}
                                />
                            : null}
                            <Question 
                                data={examData?.questions.find(q => q.question_id === navigationData.currentQuestion)} 
                                handleAnswerQuestion={handleAnswerQuestion} 
                                handleOpenReader={()=>setOpenReader(true)} 
                                navigationData={navigationData}
                            />
                        </div>
                    </section>
                    {isMobile ?
                        <PaginationMobile 
                            questions={examData?.questions} 
                            userModules={examData?.user_modules} 
                            navigationData={navigationData} 
                            handleChangeQuestion={handleChangeQuestion} 
                            handleCloseExam={handleAskEndExam} 
                            handleCloseModule={handleAskEndModule} 
                            handleChangeModule={handleChangeModule} 
                            remainingQuestions={remainingQuestions()}
                        />
                    : 
                        <PaginationControl 
                            navigationData={navigationData} 
                            handleChangeQuestion={handleChangeQuestion} 
                            questions={examData?.questions} 
                        />
                    }
                </>
            : "" }
            <EndModal props={confirmModal}/>
        </section>
    )
}