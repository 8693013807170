
import user_img from './icons/user.svg'
import formazione_img from './icons/formazione.svg'
import notifiche_img from './icons/notifiche.svg'
import verifica_img from './icons/verifica.svg'
import { NavLink, useLocation } from 'react-router-dom'

import './NavBar.css'

import tf_logo from '../../assets/images/logo_tf.svg'

import { useSelector } from 'react-redux'
import { selectTranslations } from '../../services/i18n/i18nSlice'
import { selectAppInfo } from '../../services/utils/appInfoSlice'
import { API_URL } from '../../config/constants'
import { selectAppAuthToken } from '../../services/auth/authSlice'
import { isWebview } from '../../utils/utils'
import { isMobile } from 'react-device-detect'

export default function NavBar(props) {

    const activeClassName = 'active_section'
    const t = useSelector(selectTranslations)
    const appInfo = useSelector(selectAppInfo)
    const userAuth = useSelector(selectAppAuthToken)

    const {pathname} = useLocation()

    return(
        <nav id='mainNavBar' className={isMobile && isWebview() ? 'webview_navbar' : ''} hidden={props.hide || !userAuth}>
            <div id='navbarLogo'>
                <img className='d-none d-md-block' src={appInfo?.personalizations?.logo_url ? `${API_URL}${appInfo?.personalizations?.logo_url}` : tf_logo} alt=''/>
            </div>
            <ul>
                { appInfo?.personalizations?.enabled_sections?.trainings &&<li>
                    <NavLink to={'/'}
                        className={({ isActive }) => isActive || pathname.startsWith('/folder') ? activeClassName : undefined }>
                        <img src={formazione_img} alt='' />
                        <span>{t.sections.trainings}</span>
                    </NavLink>
                </li> }
                { appInfo?.personalizations?.enabled_sections?.exams && <li>
                    <NavLink to={'/exams'} className={({ isActive }) => isActive ? activeClassName : undefined }>
                        <img src={verifica_img} alt='' />
                        <span>{t.sections.exams}</span>
                    </NavLink>
                </li> }
                <li>
                    <NavLink to={'/account'} className={({ isActive }) => isActive ? activeClassName : undefined }>
                        <img src={user_img} alt='' />
                        <span>{t.sections.account}</span>
                    </NavLink>
                </li>
            </ul>
        </nav>
    )
}